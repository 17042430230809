import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// components
import AboutPage from "./Pages/AboutPage";
import BottomNav from "./components/BottomNav";
import Composers from "./components/Composers/Composers";
import ComposerPage from "./components/Composers/ComposerPage";
import Dashboard from "./components/Dashboard/Dashboard";
import Header from './components/Header';
import Prayers from "./components/Prayers/Prayers";
import PrayerPage from "./components/Prayers/PrayerPage";
import Hymns from "./components/Hymns/Hymns";
import HymnPage from "./components/Hymns/HymnPage";
import NotFound from "./components/NotFound";

const theme = createTheme({
  typography: {
    fontFamily: [
      'Lato',
      'sans-serif'
    ].join(',')
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div className="App">
          <Header></Header>
          <Routes>
            <Route path='/' element={<Dashboard />} />
            <Route path='/about' element={<AboutPage />} />
            <Route path='/composers' element={<Composers />} />
            <Route path='/composer/:id' element={<ComposerPage />} />
            <Route path='/hymns' element={<Hymns />} />
            <Route path='/hymn/:id' element={<HymnPage />} />
            <Route path='/prayers' element={<Prayers />} />
            <Route path='/prayer/:id' element={<PrayerPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>

          <BottomNav />
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
