import React, { useEffect, useState } from "react";
import ComposerCard from "./ComposerCard";
import Spinner from "../Spinner";
import Container from '@mui/material/Container';
import LandingPageSummaryCard from "../LandingPageSummaryCard";

const api_endpoint = 'https://wkcc-api.maenterawasi.com/api/composers';

const Composers = () => {
  const [composers, setComposers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getComposers = async () => {
    try {
      const response = await fetch(api_endpoint);
      if (!response.ok) {
        throw new Error('Failed to fetch composers');
      }
      const data = await response.json();
      setComposers(data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    getComposers();
  }, []);

  return (
    <>
      <Container>
        <LandingPageSummaryCard title={'Composers'} summary={'Browse through all hymn composers and see hymns that have been composed by them.'} />
        {loading ? (
          <Spinner />
        ) : error ? (
          <div>Error: {error}</div>
        ) : composers?.length > 0 ? (
          composers.map((composer) => (
            <ComposerCard key={composer.ID} composer={composer} />
          ))
        ) : (
          <div>No composers found</div>
        )}
      </Container>
    </>
  )
}

export default Composers;
