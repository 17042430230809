import React from "react";
import { Link, useLocation } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from '@mui/material/Paper';
import MusicNoteOutlinedIcon from '@mui/icons-material/MusicNoteOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import ChurchOutlinedIcon from '@mui/icons-material/ChurchOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

const BottomNav = () => {
  const location = useLocation();
  const [value, setValue] = React.useState(location.pathname);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper elevation={3} sx={{ position: "fixed", bottom: 0, width: "100%", minHeight: '75px' }}>
      <BottomNavigation value={value} onChange={handleChange} showLabels>
        <BottomNavigationAction
          component={Link}
          to={"/"}
          label="Home"
          icon={<HomeOutlinedIcon />}
        />
        <BottomNavigationAction
          component={Link}
          to={"/hymns"}
          label="Hymns"
          icon={<MusicNoteOutlinedIcon />}
        />
        <BottomNavigationAction
          component={Link}
          to={"/prayers"}
          label="Prayers"
          icon={<ChurchOutlinedIcon />}
        />
        <BottomNavigationAction
          component={Link}
          to={"/composers"}
          label="Composers"
          icon={<PeopleOutlineOutlinedIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNav;
