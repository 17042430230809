import { Link } from "react-router-dom";
import { useState } from "react";
import { styled } from '@mui/material/styles';

// MUI components
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const StyledDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    width: 250,
    backgroundColor: '#4B9955ff',
    color: '#ffffff',
  },
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  width: '80%',
  margin: '0 auto',
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
  },
  '& .MuiListItemText-root': {
    textAlign: 'left',
  },
  '& .MuiListItemText-primary': {
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setIsDrawerOpen(open);
  };

  return (
    <Box sx={{ flexGrow: 1, marginBottom: '20px' }}>
      <AppBar position="static" sx={{ backgroundColor: '#4B9955ff' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="body1" component="div" sx={{ flexGrow: 1 }}>
            Kiribati English Prayer and Hymns
          </Typography>
        </Toolbar>
      </AppBar>

      <StyledDrawer anchor='left' open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250 }}>
          <List>
            <StyledListItem button component={Link} to="/" activeClassName="active">
              <HomeOutlinedIcon />
              <Typography variant="body1" component="span">Home</Typography>
            </StyledListItem>
            <StyledListItem button component={Link} to="/about" activeClassName="active">
              <InfoOutlinedIcon />
              <Typography variant="body1" component="span">About</Typography>
            </StyledListItem>
          </List>
        </Box>
      </StyledDrawer>
    </Box>
  );
}

export default Header;
