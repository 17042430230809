import { Link } from "react-router-dom";

// MUI components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

const PrayerCard = ({ prayer }) => {
  return (
    <Link to={`/prayer/` + prayer.ID} style={{ textDecoration: 'none' }}>
      <Card sx={{ margin: '10px 0px' }}>
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="body1" component="div">
              {prayer.Title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  )
}

export default PrayerCard;