import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

// components
import HymnCard from "../Hymns/HymnCard";
import Spinner from "../Spinner";

// MUI components
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

const ComposerPage = () => {
  const [composer, setComposer] = useState(null);
  const { id } = useParams();
  const api_endpoint = `https://wkcc-api.maenterawasi.com/api/composer/${id}`;

  const getComposer = async () => {
    try {
      const response = await fetch(api_endpoint);
      if (response.ok) {
        const data = await response.json();
        setComposer(data);
      } else {
        console.error("Failed to fetch composer data:", response.statusText);
      }
    } catch (error) {
      console.error("Failed to fetch composer data:", error);
    }
  };

  useEffect(() => {
    getComposer();
  }, [id]); // Include 'id' as a dependency to fetch data when 'id' changes

  return (
    <Container>
      <Typography variant="body1">
        {composer ? (
          <>
            Hymns composed by {composer.FullName}
            {composer.Hymns?.length > 0 ? (
              composer.Hymns.map((hymn) => (
                <HymnCard key={hymn.ID} hymn={hymn}></HymnCard>
              ))
            ) : (
              <Typography variant="body2">No hymns found.</Typography>
            )}
          </>
        ) : (
          <Spinner />
        )}
      </Typography>
    </Container>
  );
};

export default ComposerPage;
