import React from "react";
import LandingPageCard from "../LandingPageCard";
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';

const Dashboard = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <LandingPageCard title={'Hymns'} info={'See all hymns both in Kiribati and English versions.'} url={'/hymns'} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <LandingPageCard title={'Prayers'} info={'See all prayers both in Kiribati and English versions.'} url={'/prayers'} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <LandingPageCard title={'Composers'} info={'Browse through all hymn composers and see hymns that have been composed by them.'} url={'/composers'} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Dashboard;
