import React, { useEffect, useState } from "react";
import PrayerCard from "./PrayerCard";
import Spinner from "../Spinner";
import Container from '@mui/material/Container';
import LandingPageSummaryCard from "../LandingPageSummaryCard";

const api_endpoint = 'https://wkcc-api.maenterawasi.com/api/prayers';

const Prayers = () => {
  const [prayers, setPrayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getPrayers = async () => {
    try {
      const response = await fetch(api_endpoint);
      if (!response.ok) {
        throw new Error('Failed to fetch prayers');
      }
      const data = await response.json();
      setPrayers(data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    getPrayers();
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : error ? (
        <div>Error: {error}</div>
      ) : (
        <Container>
          <LandingPageSummaryCard title={'Prayers'} summary={'See all prayers both in Kiribati and English versions.'} />
          {prayers?.length > 0 ? (
            prayers.map((prayer) => (
              <PrayerCard key={prayer.ID} prayer={prayer} />
            ))
          ) : (
            <div>No prayers found</div>
          )}
        </Container>
      )}
    </>
  )
}

export default Prayers;