import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DOMPurify from 'dompurify';

// MUI components
import { Container } from "@mui/material";
import Typography from '@mui/material/Typography';

const HymnPage = () => {
  const [hymn, setHymn] = useState([]);
  const { id } = useParams();
  // const api_endpoint = `http://localhost/api/hymn/` + id;
  const api_endpoint = `https://wkcc-api.maenterawasi.com/api/hymn/` + id;

  const getHymn = async () => {
    try {
      const response = await fetch(api_endpoint);
      const data = await response.json();

      setHymn(data);
    } catch (error) {
      console.error("Failed to fetch hymn:", error);
    }
  }

  useEffect(() => {
    getHymn();
  }, []);

  const cleanHTML_KiribatiTranslation = DOMPurify.sanitize(hymn.KiribatiTranslation, {
    USE_PROFILES: { html: true }
  });
  const cleanHTML_EnglishTranslation = DOMPurify.sanitize(hymn.EnglishTranslation, {
    USE_PROFILES: { html: true }
  });

  return (
    <Container>
      <Typography variant="h5">
        {hymn.Title}
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '20px' }}>
        Composer: {hymn.Composer}
      </Typography>

      <Typography variant="body2">
        Kiribati version
      </Typography>
      <div dangerouslySetInnerHTML={{ __html: cleanHTML_KiribatiTranslation }} />
      <Typography variant="body2">
        English version
      </Typography>
      <div dangerouslySetInnerHTML={{ __html: cleanHTML_EnglishTranslation }} />
    </Container>
  )
}

export default HymnPage;
