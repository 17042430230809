// MUI components
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

const AboutPage = () => {
  return (
    <>
      <Container>
        <Typography sx={{ marginBottom: '15px' }} variant="h5">
          About WKCC and the WKCC Kiribati and English Prayer and Hymn web app
        </Typography>
        <Typography sx={{ marginBottom: '15px' }}>
          The Wellington Kiribati Catholic Community has developed a bilingual web app that offers a collection of Kiribati and English prayers and hymns. This app provides a valuable resource for members of the Kiribati community in Wellington, New Zealand, and beyond, allowing them to access traditional prayers and hymns in both languages. The app offers an intuitive user interface, making it easy to navigate and select the desired prayers and hymns. With this web app, the Wellington Kiribati Catholic Community has created an important tool for preserving and sharing their cultural and religious heritage with a wider audience.
        </Typography>
        <Typography sx={{ marginBottom: '15px' }}>
          For more information, send us an email at example@email.com
        </Typography>
      </Container>
    </>
  );
}

export default AboutPage;