import React, { useEffect, useState } from "react";
import HymnCard from "./HymnCard";
import Spinner from "../Spinner";
import Container from '@mui/material/Container';
import LandingPageSummaryCard from "../LandingPageSummaryCard";

const api_endpoint = 'https://wkcc-api.maenterawasi.com/api/hymns';

const Hymns = () => {
  const [hymns, setHymns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getHymns = async () => {
    try {
      const response = await fetch(api_endpoint);
      if (!response.ok) {
        throw new Error('Failed to fetch hymns');
      }
      const data = await response.json();
      setHymns(data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    getHymns();
  }, []);

  return (
    <>
      <Container>
        <LandingPageSummaryCard title={'Hymns'} summary={'See all hymns both in Kiribati and English versions.'} />
        {loading ? (
          <Spinner />
        ) : error ? (
          <div>Error: {error}</div>
        ) : hymns?.length > 0 ? (
          hymns.map((hymn) => (
            <HymnCard key={hymn.ID} hymn={hymn} />
          ))
        ) : (
          <div>No hymns found</div>
        )}
      </Container>
    </>
  )
}

export default Hymns;
