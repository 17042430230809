import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';

const LandingPageSummaryCard = ({ title, summary }) => {
  return (
    <>
      <div style={{ marginBottom: '30px' }}>
        <Typography variant='h5'>
          {title}
        </Typography>
        <Typography variant='body1'>
          {summary}
        </Typography>
      </div >
    </>
  );
}

export default LandingPageSummaryCard;